import React from 'react';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';

import { useJurorFilters } from '../../../hooks/useJurorFilters';
import ResultsList from '../../ui/resultsList';
import TeaserItem from './components/juryTeaserItem';
import { navigate } from 'gatsby';
import { combine } from '../../../helpers/styles';
import * as styles from './styles.module.scss';

const JuryFilterList = ({
  resetSearchTerm = () => navigate('?', { replace: true }),
  includeSearch,
}) => {
  const [query, _] = useQueryParams({
    awardId: ArrayParam,
    yearId: ArrayParam,
    countryId: ArrayParam,
    sort: StringParam,
    find: StringParam,
  });

  const queryFilters = {
    awards: query.awardId ? query.awardId.map(Number) : [2],
    country: query.countryId ? query.countryId.map(String) : [],
    time: query.yearId ? query.yearId.map(Number) : [2],
    sort: query.sort ? query.sort : 'desc',
    find: query.find,
  };

  const onSubmit = (searchTerm) => {
    navigate(searchTerm, {
      replace: true,
    });
  };

  return (
    <div className={combine(includeSearch && styles.wrapper)}>
      <ResultsList
        searchTerm={query.find}
        resetSearchTerm={resetSearchTerm}
        queryFilters={queryFilters}
        filtersHook={useJurorFilters}
        itemRenderer={TeaserItem}
        countLabelKey="label.countProfiles"
        type="juror"
        hideFilterOnLoad={includeSearch}
        query={query}
        includeSearch={includeSearch}
        onSearchSubmit={onSubmit}
      />
    </div>
  );
};

export default JuryFilterList;
